<template>
  <div>
    <div>
      <feather-icon
        icon="ArrowLeftIcon"
        svgClasses="h-8"
        style="cursor: pointer"
        @click="$router.push('/Patient')"
      />

      <patient-Tab class="mt-4" :mainTabName="'MedicalHistory'" />
    </div>

    <vs-card>
      <div class="ml-5 mr-10">
        <div class="w-full ml-5 mr-5">
          <div div class="vx-row mt-5">
            <b class="xl:w-3/12 text-left m-2">{{ $t("MaritalStatus") }}</b>

            <vs-radio
              class="vx-col lg:w-1/7"
              v-model="patientModel.MaritalStatusID"
              disabled
              :vs-value="1"
              >{{ $t("Single") }}</vs-radio
            >
            <vs-radio
              class="vx-col lg:w-1/7"
              v-model="patientModel.MaritalStatusID"
              disabled
              :vs-value="2"
              >{{ $t("Divorced") }}</vs-radio
            >
            <vs-radio
              class="vx-col lg:w-1/7"
              v-model="patientModel.MaritalStatusID"
              disabled
              :vs-value="3"
              >{{ $t("Widowed") }}</vs-radio
            >
            <vs-radio
              class="vx-col lg:w-1/7"
              v-model="patientModel.MaritalStatusID"
              disabled
              :vs-value="4"
              >{{ $t("Married") }}</vs-radio
            >
          </div>

          <div class="vx-row mt-5">
            <b class="xl:w-3/12 text-left m-2">{{ $t("HaveChildren") }}</b>
            <vs-radio
              class="vx-col lg:w-1/7"
              v-model="patientModel.HaveChildren"
              vs-name="HaveChildren"
              disabled
              :vs-value="true"
              >{{ $t("yes") }}</vs-radio
            >

            <vs-radio
              class="vx-col lg:w-1/7"
              v-model="patientModel.HaveChildren"
              vs-name="HaveChildren"
              disabled
              :vs-value="false"
              >{{ $t("no") }}</vs-radio
            >

            <p class="vx-col lg:w-1/7">{{ $t("Ifyeshowmany") }}:</p>
            <!-- :disabled="!patientModel.HaveChildren" -->
            <vs-input
              class="vx-col lg:w-1/8"
              disabled
              v-model="patientModel.NumOfChildren"
              :placeholder="$t('NumOfChildren')"
            />

            <p class="vx-col">{{ $t("Age") }}:</p>
            <vs-input
              class="vx-col lg:w-1/8"
              v-model="patientModel.AgeOfChildren"
              disabled
              :placeholder="$t('5,8,6,..')"
            />
          </div>

          <div class="vx-row mt-5">
            <b class="xl:w-3/12 text-left m-2"> {{ $t("Internationalinsurance") }}</b>

            <vs-radio
              class="vx-col lg:w-1/7"
              v-model="patientModel.PatientMedicalHistory.IsHaveInternationalInsurance"
              vs-name="Internationalinsurance"
              disabled
              :vs-value="true"
              >{{ $t("yes") }}</vs-radio
            >
            <vs-radio
              @change="
                patientModel.PatientMedicalHistory.InsuranceCompanyID = null;
                patientModel.PatientMedicalHistory.InsuranceCompanyName = null;
              "
              class="vx-col lg:w-1/7"
              v-model="patientModel.PatientMedicalHistory.IsHaveInternationalInsurance"
              vs-name="Internationalinsurance  "
              disabled
              :vs-value="false"
              >{{ $t("no") }}</vs-radio
            >

            <p class="vx-col lg:w-1/7">{{ $t("Insurancecompanyname") }}</p>
            <!-- :disabled="!patientModel.PatientMedicalHistory.IsHaveInternationalInsurance" -->
            <vs-select
              disabled
              class="vx-col lg:w-1/7"
              v-model="patientModel.PatientMedicalHistory.InsuranceCompanyID"
            >
              <vs-select-item
                :value="null"
                text="Other Company"
                style="background-color: #352566"
              />
              <vs-select-item
                :key="index"
                :value="item.ID"
                :text="item.Name"
                v-for="(item, index) in companies"
              />
            </vs-select>

            <vs-input
              v-if="
                patientModel.PatientMedicalHistory.InsuranceCompanyID == null &&
                patientModel.PatientMedicalHistory.IsHaveInternationalInsurance
              "
              disabled
              class="vx-col lg:w-1/7"
              vs-name="Insurancecompanyname"
              v-model="patientModel.PatientMedicalHistory.InsuranceCompanyName"
              :placeholder="$t('companyname')"
            />
          </div>

          <div div class="vx-row mt-5">
            <b class="xl:w-3/12 text-left m-2">
              <p>{{ $t("Areyouasmoker") }}</p></b
            >
            <vs-radio
              class="vx-col lg:w-1/7"
              v-model="patientModel.PatientMedicalHistory.IsSmoker"
              :vs-value="true"
              vs-name="Areyouasmoker"
              disabled
              >{{ $t("yes") }}</vs-radio
            >
            <vs-radio
              class="vx-col lg:w-1/7"
              v-model="patientModel.PatientMedicalHistory.IsSmoker"
              vs-name="Areyouasmoker"
              disabled
              :vs-value="false"
              >{{ $t("no") }}</vs-radio
            >
            <div class="vx-row ml-4">
              <p>{{ $t("Level") }}:</p>
              <div class="vx-col">
                <!-- :disabled="!patientModel.PatientMedicalHistory.IsSmoker" -->
                <vs-radio
                  class="m-1"
                  disabled
                  vs-name="SmokeDegreeID"
                  v-model="patientModel.PatientMedicalHistory.SmokeDegreeID"
                  :vs-value="1"
                  >{{ $t("Heavy") }}</vs-radio
                >
                <!-- :disabled="!patientModel.PatientMedicalHistory.IsSmoker" -->
                <vs-radio
                  class="m-1"
                  disabled
                  vs-name="SmokeDegreeID"
                  v-model="patientModel.PatientMedicalHistory.SmokeDegreeID"
                  :vs-value="2"
                  >{{ $t("Moderate") }}</vs-radio
                >
                <!-- :disabled="!patientModel.PatientMedicalHistory.IsSmoker" -->

                <vs-radio
                  class="m-1"
                  disabled
                  vs-name="SmokeDegreeID"
                  v-model="patientModel.PatientMedicalHistory.SmokeDegreeID"
                  :vs-value="3"
                  >{{ $t("Occasionaly") }}</vs-radio
                >
              </div>
            </div>
          </div>

          <div class="vx-row mt-5">
            <b class="xl:w-3/12 text-left m-2">
              <p>{{ $t("Doyoudrinkalcohol") }}</p></b
            >
            <vs-radio
              class="vx-col lg:w-1/5"
              vs-name="IsDrinkAlcoholCurrently"
              disabled
              @change="
                patientModel.PatientMedicalHistory.NoIquit = false;
                patientModel.PatientMedicalHistory.IsDrinkAlcoholRarely = false;
              "
              v-model="patientModel.PatientMedicalHistory.IsDrinkAlcoholCurrently"
              :vs-value="true"
              >{{ $t("Yescurrently") }}</vs-radio
            >
            <vs-radio
              vs-name="IsDrinkAlcoholCurrently"
              disabled
              class="vx-col lg:w-1/5"
              v-model="patientModel.PatientMedicalHistory.IsDrinkAlcoholRarely"
              @change="
                patientModel.PatientMedicalHistory.NoIquit = false;
                patientModel.PatientMedicalHistory.IsDrinkAlcoholCurrently = false;
              "
              :vs-value="true"
              >{{ $t("Yesbutoccasionallyrarely") }}</vs-radio
            >
            <vs-radio
              class="vx-col lg:w-1/5"
              vs-name="IsDrinkAlcoholCurrently"
              disabled
              @change="
                patientModel.PatientMedicalHistory.IsDrinkAlcoholRarely = false;
                patientModel.PatientMedicalHistory.IsDrinkAlcoholCurrently = false;
              "
              v-model="patientModel.PatientMedicalHistory.NoIquit"
              :vs-value="true"
              >{{ $t("No") }}</vs-radio
            >
          </div>

          <div class="vx-row mt-5">
            <p class="xl:w-3/12 text-left m-2">{{ $t("Howmanydrinksperday") }}:</p>
            <!-- :disabled="patientModel.PatientMedicalHistory.NoIquit" -->
            <vs-input
              disabled
              v-model="patientModel.PatientMedicalHistory.ManyDrinksAday"
              class="flex-1"
              :placeholder="$t('drinksperday')"
              vs-name="IsDrinkAlcoholCurrently"
            ></vs-input>
          </div>

          <!-- <div class="vx-row mt-5">
            <b class=" xl:w-3/12 text-left m-2">
              <p>{{ $t("Haveyougonethroughanysurgerybefore") }}</p></b
            >
            <vs-radio class="vx-col lg:w-1/7" vs-name="IsSurgeryBefore" v-model="patientModel.PatientMedicalHistory.IsSurgeryBefore" :vs-value="true">{{
              $t("yes")
            }}</vs-radio>

            <vs-radio
              class="vx-col lg:w-1/7"
              vs-name="IsSurgeryBefore"
              v-model="patientModel.PatientMedicalHistory.IsSurgeryBefore"
              :vs-value="false"
              >{{ $t("No") }}</vs-radio
            >
            <p class="vx-col lg:w-1/5 mt-3">{{ $t("Ifyesdescripe") }}:</p>
            <vs-input class="vx-col lg:w-1/5"
            :disabled="!patientModel.PatientMedicalHistory.IsSurgeryBefore"
            v-model="patientModel.PatientMedicalHistory.SurgeryBeforeName"
              :placeholder="$t('Surgeries')" >
            </vs-input>
          </div> -->

          <div class="vx-row mt-5">
            <b class="xl:w-3/12 text-left m-2">
              <p>{{ $t("Areyoudiagnosedwithloworhighpressure") }}</p>
            </b>
            <vs-radio
              class="vx-col lg:w-1/7"
              v-model="patientModel.PatientMedicalHistory.IsHavePresssure"
              :vs-value="true"
              vs-name="IsHavePresssure"
              disabled
              >{{ $t("Highpressure") }}</vs-radio
            >
            <div class="vx-col lg:w-1/7"></div>
            <vs-radio
              class="vx-col lg:w-1/5"
              v-model="patientModel.PatientMedicalHistory.IsLowPressure"
              vs-value="15"
              vs-name="IsHavePresssure"
              disabled
              >{{ $t("Lowpressure") }}</vs-radio
            >

            <vs-radio
              class="vx-col lg:w-1/5"
              v-model="patientModel.PatientMedicalHistory.IsHavePresssure"
              :vs-value="false"
              vs-name="IsHavePresssure"
              disabled
              >{{ $t("no") }}</vs-radio
            >
          </div>

          <div class="vx-row mt-5">
            <b class="xl:w-3/12 sm:w-1/4 text-left m-2">
              <p>{{ $t("Areyouasthmatic") }}</p>
            </b>
            <vs-radio
              class="vx-col lg:w-1/7"
              vs-name="Areyouasthmatic"
              disabled
              v-model="patientModel.PatientMedicalHistory.IsHaveAsthmatic"
              :vs-value="true"
              >{{ $t("yes") }}</vs-radio
            >
            <vs-radio
              @change="patientModel.PatientMedicalHistory.DiseaseNote = null"
              class="vx-col lg:w-1/7"
              v-model="patientModel.PatientMedicalHistory.IsHaveAsthmatic"
              :vs-value="false"
              vs-name="Areyouasthmatic"
              disabled
              >{{ $t("no") }}</vs-radio
            >
            <p class="vx-col lg:w-1/5 mt-3">{{ $t("Ifyesdescripe") }}:</p>
            <!-- :disabled="!patientModel.PatientMedicalHistory.IsHaveAsthmatic" -->

            <vs-input
              class="vx-col lg:w-1/5"
              v-model="patientModel.PatientMedicalHistory.DiseaseNote"
              :placeholder="$t('DiseaseNote')"
              disabled
            ></vs-input>
          </div>

          <div class="vx-row mt-5">
            <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
              <p>{{ $t("Anycancerdiagnosishistoryinyourfamily") }}</p></b
            >
            <vs-radio
              class="vx-col lg:w-1/7"
              vs-name="ISHaveCancerHistoryinFamily"
              disabled
              v-model="patientModel.PatientMedicalHistory.ISHaveCancerHistoryinFamily"
              :vs-value="true"
              >{{ $t("yes") }}</vs-radio
            >

            <vs-radio
              @change="
                patientModel.PatientMedicalHistory.CancerHistoryinFamilyNote = null
              "
              class="vx-col lg:w-1/7"
              vs-name="ISHaveCancerHistoryinFamily"
              disabled
              v-model="patientModel.PatientMedicalHistory.ISHaveCancerHistoryinFamily"
              :vs-value="false"
              >{{ $t("No") }}</vs-radio
            >
            <p class="vx-col lg:w-1/5 mt-3">{{ $t("Ifyesdescripe") }}:</p>
            <!-- :disabled="!patientModel.PatientMedicalHistory.ISHaveCancerHistoryinFamily" -->

            <vs-input
              class="vx-col lg:w-1/5"
              v-model="patientModel.PatientMedicalHistory.CancerHistoryinFamilyNote"
              :placeholder="$t('CancerHistoryinFamilyNote')"
              disabled
            >
            </vs-input>
          </div>

          <div class="vx-row mt-5">
            <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
              <p>{{ $t("HaveDisease") }}</p></b
            >
            <vs-radio
              class="vx-col lg:w-1/7"
              vs-name="HaveDisease"
              disabled
              v-model="patientModel.PatientMedicalHistory.IsHaveDisease"
              :vs-value="true"
              >{{ $t("yes") }}</vs-radio
            >

            <vs-radio
              @change="patientModel.PatientMedicalHistory.DiseaseText = null"
              class="vx-col lg:w-1/7"
              vs-name="HaveDisease"
              disabled
              v-model="patientModel.PatientMedicalHistory.IsHaveDisease"
              :vs-value="false"
              >{{ $t("No") }}</vs-radio
            >
            <p class="vx-col lg:w-1/5 mt-3">{{ $t("Ifyesdescripe") }}:</p>
            <!-- :disabled="!patientModel.PatientMedicalHistory.IsHaveDisease" -->

            <vs-input
              class="vx-col lg:w-1/5"
              v-model="patientModel.PatientMedicalHistory.DiseaseText"
              :placeholder="$t('DiseaseText')"
              disabled
            >
            </vs-input>
          </div>

          <div class="vx-row mt-5">
            <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
              <p>{{ $t("HaveAids") }}</p></b
            >
            <vs-radio
              class="vx-col lg:w-1/7"
              vs-name="HaveAids"
              disabled
              v-model="patientModel.PatientMedicalHistory.ISHaveAids"
              :vs-value="true"
              >{{ $t("yes") }}</vs-radio
            >

            <vs-radio
              @change="patientModel.PatientMedicalHistory.AidsDate = null"
              class="vx-col lg:w-1/7"
              vs-name="HaveAids"
              disabled
              v-model="patientModel.PatientMedicalHistory.ISHaveAids"
              :vs-value="false"
              >{{ $t("No") }}</vs-radio
            >
            <p class="vx-col lg:w-1/5 mt-3">{{ $t("Ifyeswhen") }}:</p>
            <!-- :disabled="!patientModel.PatientMedicalHistory.ISHaveAids" -->
            <datepicker
              :placeholder="$t('AidsDate')"
              disabled
              v-model="patientModel.PatientMedicalHistory.AidsDate"
              class="vx-col lg:w-1/5"
            ></datepicker>
          </div>

          <div class="vx-row mt-5">
            <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
              <p>{{ $t("HaveHeartProblem") }}</p></b
            >
            <vs-radio
              class="vx-col lg:w-1/7"
              vs-name="HaveHeartProblem"
              disabled
              v-model="patientModel.PatientMedicalHistory.ISHaveHeartProblem"
              :vs-value="true"
              >{{ $t("yes") }}</vs-radio
            >

            <vs-radio
              class="vx-col lg:w-1/7"
              vs-name="HaveHeartProblem"
              disabled
              v-model="patientModel.PatientMedicalHistory.ISHaveHeartProblem"
              :vs-value="false"
              >{{ $t("No") }}</vs-radio
            >
          </div>
          <div class="vx-row mt-5">
            <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
              <p>{{ $t("LastBloodAnalysis") }}</p></b
            >

            <datepicker
              :placeholder="$t('LastBloodAnalysis')"
              disabled
              v-model="patientModel.PatientMedicalHistory.LastBloodAnalysisDate"
              class="vx-col lg:w-1/5"
            ></datepicker>
          </div>

          <div class="vx-row mt-5">
            <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
              <p>{{ $t("Allergies") }}</p></b
            >

            <vs-input
              class="vx-col lg:w-1/5"
              v-model="patientModel.PatientMedicalHistory.Allergies"
              :placeholder="$t('AllergiesNote')"
              disabled
            >
            </vs-input>
          </div>

          <div class="vx-row">
            <vs-card class="w-full lg:w-2/5 m-5">
              <div class="vx-row mt-5">
                <b class="lg:w-1/2 text-left ml-5 mr-5">
                  <p>{{ $t("Haveyougonethroughanysurgerybefore") }}</p></b
                >
                <vs-radio
                  class="vx-col lg:w-1/5"
                  vs-name="IsSurgeryBefore"
                  disabled
                  v-model="patientModel.PatientMedicalHistory.IsSurgeryBefore"
                  :vs-value="true"
                  >{{ $t("yes") }}</vs-radio
                >

                <vs-radio
                  class="vx-col lg:w-1/5"
                  vs-name="IsSurgeryBefore"
                  disabled
                  v-model="patientModel.PatientMedicalHistory.IsSurgeryBefore"
                  :vs-value="false"
                  >{{ $t("No") }}</vs-radio
                >
              </div>

              <div
                style="text-decoration-line: underline"
                v-if="
                  patientModel.PatientMedicalHistory != null &&
                  patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries !=
                    undefined &&
                  patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries
                    .length > 0
                "
              >
                <div
                  v-for="(item, index) in patientModel.PatientMedicalHistory
                    .PatientMedicalHistoryPastSurgeries"
                  :key="index"
                >
                  <div class="vx-row m-5">
                    <h2 class="vx-col lg:w-1/4" style="color: #454a62; font-size: 19px">
                      {{ item.SurgeryName }}
                    </h2>
                  </div>
                </div>
              </div>
            </vs-card>

            <vs-card class="w-full lg:w-2/5 m-5">
              <b class="lg:w-1/2 text-left ml-5 mr-5">
                <p>{{ $t("Medication") }}</p></b
              >

              <div
                style="text-decoration-line: underline"
                v-if="
                  patientModel.PatientMedicalHistory != null &&
                  patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications !=
                    undefined &&
                  patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications
                    .length > 0
                "
              >
                <div
                  v-for="(item, index) in patientModel.PatientMedicalHistory
                    .PatientMedicalHistoryMedications"
                  :key="index"
                >
                  <div class="vx-row mt-5">
                    <h2 class="vx-col lg:w-1/4" style="color: #454a62; font-size: 19px">
                      {{ item.MedicineName }}
                    </h2>
                    <h2 class="vx-col lg:w-1/4" style="color: #454a62; font-size: 19px">
                      {{ item.Dose }}
                    </h2>
                    <h2 class="vx-col lg:w-1/4" style="color: #454a62; font-size: 19px">
                      {{ item.DurationInDay }}
                    </h2>
                  </div>
                </div>
              </div>
            </vs-card>
          </div>
        </div>
      </div>
    </vs-card>

    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button type="border" color="danger" :to="{ name: 'Patient' }">{{
            $t("back")
          }}</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";

import moduleSmokeDegree from "@/store/settings/smokeDegree/moduleSmokeDegree.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import patientTab from "@/views/tabs/patientTab.vue";
import Datepicker from "vuejs-datepicker";
import moduleInsuranceCompany from "@/store/settings/insuranceCompany/moduleInsuranceCompany.js";

export default {
  components: {
    vSelect,
    Datepicker,
    patientTab,
  },

  computed: {
    SmokeDegrees() {
      return this.$store.state.SmokeDegreeList.SmokeDegrees;
    },
    companies() {
      return this.$store.state.InsuranceCompanyList.InsuranceCompanies;
    },
  },
  data() {
    return {
      patientMedicalHistory: {},
      isMounted: false,
      patientModel: {
        PatientMedicalHistory: {
          PatientMedicalHistoryMedications: [],
          PatientMedicalHistoryPastSurgeries: [],
        },
      },
    };
  },
  methods: {},
  created() {
    if (!moduleSmokeDegree.isRegistered) {
      this.$store.registerModule("SmokeDegreeList", moduleSmokeDegree);
      moduleSmokeDegree.isRegistered = true;
    }

    this.$store.dispatch("SmokeDegreeList/GetAllSmokeDegrees");

    if (!moduleInsuranceCompany.isRegistered) {
      this.$store.registerModule("InsuranceCompanyList", moduleInsuranceCompany);
      moduleInsuranceCompany.isRegistered = true;
    }

    this.$store.dispatch("InsuranceCompanyList/GetAllInsuranceCompanies");

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      debugger;
      this.$vs.loading();
      this.$store.dispatch("patientList/GetPatientForMainData", ID).then((res) => {
        this.patientModel = res.data.Data;
        this.$store.commit("SET_SetTitle", "" + this.patientModel.NameEN);
        if (
          this.patientModel.PatientMedicalHistory == null ||
          this.patientModel.PatientMedicalHistory == undefined
        ) {
          this.patientModel.PatientMedicalHistory = {};
        } else {
          if (this.patientModel.NumOfChildren > 0) {
            this.patientModel.HaveChildren = true;
          }
        }
        this.$vs.loading.close();
      });
    }
  },
};
</script>
<style></style>
